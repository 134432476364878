<template>
  <el-dialog
    v-model="showDialog"
    title=""
    :before-close="handleClose"
    lock-scroll
    :show-close="false"
    style="height: 77%;overflow-y: scroll"
  >
    <template #title>
      <div class="header-title" v-if="isDetails">
        <img
          style="cursor: pointer; width: 30px; height: 30px"
          src="@/assets/images/order/return1.png"
          @click="back"
        />
        <img
          style="cursor: pointer; width: 20px; height: 20px"
          src="@/assets/images/order/ch.png"
          @click="handleClose"
        />
      </div>
      <div class="header-title" style="justify-content: flex-end" v-else>
        <img
          style="cursor: pointer; width: 20px; height: 20px"
          src="@/assets/images/order/ch.png"
          @click="handleClose"
        />
      </div>
    </template>
    <div class="supportStart-5" v-if="!isDetails">
      <div class="supportStart-5-1" style="display: flex">
        <el-form size="large" @submit.native.prevent>
          <el-form-item label="请输入主机SN:">
            <el-input
              class="supportStart-5-1-2"
              placeholder="请输入主机SN"
              v-model="keyWords"
              size="large"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="supportStart-5-1-3" @click="queryList">
          <img
            style="width: 14px; height: 14px"
            src="../../support/images/search.png"
          />
          <span class="search">搜索</span>
        </div>
      </div>
      <div
        v-if="Object.keys(qualityInfo).length"
        style="padding-bottom: 38px; overflow: auto; height: 35rem"
      >
        <div class="content-theme">
          <div class="btn-price">
            <div class="btn-content">
              <div class="g-1">
                <p class="g-1-2">
                  <span>发货日期：</span
                  ><span>{{ qualityInfo.createdTime }}</span>
                </p>
                <p class="g-1-2">
                  <span>主机SN：</span
                  ><span>{{ qualityInfo.masterSnCode }}</span>
                </p>
                <p class="g-1-2">
                  <span>商品名称：</span
                  ><span>{{ qualityInfo.productName }}</span>
                </p>
                <p class="g-1-2">
                  <span>关联订单号：</span
                  ><span>{{ qualityInfo.orderNumber }}</span>
                </p>
                <p class="g-1-2">
                  <span>主机质保期：</span
                  ><span
                    >{{splitTime(qualityInfo.qualityStartTime)  }}~{{
                    splitTime(qualityInfo.qualityEndTime)
                    }}</span
                  >
                </p>
              </div>
            </div>
            <div class="btn-p-btn">
              <p class="btn-p-btn-1" @click="toDetail">质保详情</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <el-form>
        <el-form-item>
          <div style="font-size: 18px; font-weight: 400; color: #333333">
            质保详情
          </div>
        </el-form-item>

        <el-form-item label="商品名称:">
          {{ info.productName }}
        </el-form-item>

        <el-form-item label="关联订单号:">
          {{ info.orderNumber }}
        </el-form-item>

        <el-form-item label="发货时间:">
          {{ info.createdTime }}
        </el-form-item>

        <el-form-item>
          <div style="font-size: 18px; font-weight: 400; color: #333333">
            主机质保
          </div>
        </el-form-item>

        <el-form-item label="主机SN:">
          {{ info.masterSnCode }}
        </el-form-item>

        <el-form-item label="主机质保期:">
          <div>{{splitTime(info.qualityStartTime)  }}~{{splitTime(info.qualityEndTime)  }}</div>
        </el-form-item>

        <el-form-item>
          <div style="font-size: 18px; font-weight: 400; color: #333333">
            物料质保
          </div>
        </el-form-item>

        <el-form-item>
          <el-table stripe :data="info.qualityDTOs" >
            <el-table-column
              label="物料类型"
              prop="materialType"
              width="100"
              align="center"
            ></el-table-column>
            <el-table-column
              label="物料名称"
              prop="materialName"
              min-width="100"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="物料属性"
              prop="materialAttr"
              width="100"
              align="center"
            ></el-table-column>
            <el-table-column
              label="数量"
              prop="materialCount"
              width="100"
              align="center"
            ></el-table-column>
            <el-table-column
              label="物料SN"
              prop="materialSn"
              width="100"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="质保期"
              width="200"
              align="center"
              show-overflow-tooltip
            >
              <template #default="{ row }">
                {{splitTime(row.qualityStartTime)  }}~{{splitTime(row.qualityEndTime)  }}
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, defineEmits, reactive } from "vue";
import { qualityQueryOne, qualityDetail } from "@/api/support";
import { ElMessage } from "element-plus";

const emit = defineEmits(["closeDialog"]);

const qualityInfo = ref({}); //列表对象
const showDialog = ref(true);
const keyWords = ref(""); //搜索条件
const isDetails = ref(false); //是否是详情
const info = ref({}); //详情对象

const queryList = () => {
  if (!keyWords.value) {
    ElMessage({
      message: "请输入主机SN码",
      type: "error",
    });
    return;
  }
  qualityQueryOne(keyWords.value).then((res) => {
    qualityInfo.value = res.data || {};
    if (!res.data){
      ElMessage({
        message:'主机SN不存在',
        type: "error",
      })
    }
  });
};

//质保期不需要时分秒
const splitTime=(time)=>{
  if (!time){
    return
  }
  return time.split(' ')?.[0]
}


// 关闭弹出框
const handleClose = () => {
  emit("closeDialog");
};

//质保详情按钮
const toDetail = () => {
  isDetails.value = true;
  qualityDetail(qualityInfo.value).then((res) => {
    info.value = res.data;
  });
};

//返回列表
const back=()=>{
  isDetails.value=false
  info.value=''
}

</script>

<style scoped lang="less">
.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.supportStart-5 {
  width: 100%;
  background: #ffffff;
  position: relative;
  height: 600px;

  .supportStart-5-1 {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .supportStart-5-1-1 {
      width: 220px;

      /deep/ .el-select__wrapper {
        height: 40px; /* 设置为你需要的高度 */
        line-height: 40px;
      }
    }

    .supportStart-5-1-2 {
      width: 574px;
      height: 40px;
      background: #ffffff;
      border-radius: 5px;
      margin: 0px 0 0 12px;
    }

    .supportStart-5-1-4 {
      width: 277px;
      height: 40px;
      background: #ffffff;
      border-radius: 5px;
      margin: 0 0 0 12px;
    }

    .supportStart-5-1-3 {
      margin-left: 24px;
      width: 90px;
      height: 40px;
      line-height: 40px;
      background: #c21917;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;

      .search {
        width: 29px;
        height: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-left: 3px;
      }
    }

    /deep/ .el-select .el-input {
      display: flex;
      height: 40px;
      background: #f2f2f2;
      width: 168px;
    }
  }

  .content-theme {
    width: 100%;
    height: 192px;
    border: 1px solid #aaaaaa;
    margin-top: 20px;

    .theme-1-1 {
      width: 885px;
      height: 60px;
      display: flex;

      .theme-1 {
        margin-left: 30px;
        margin-right: 41px;

        img {
          display: inline-block;
          width: 24px;
          height: 24px;
          margin-right: 5px;
          margin-top: -5px;
        }

        span {
          display: inline-block;
          font-size: 18px;
          margin-top: 20px;
          font-weight: 400;
          color: #ce1200;
        }
      }

      .theme-2 {
        margin-top: 24px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  .border {
    border-bottom: 1px solid #e1e1e1;
  }

  .btn-price {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;

    .btn-content {
      margin-bottom: 32px;
      margin-left: 32px;

      .g-1 {
        margin-top: 24px;

        .g-1-1 {
          width: 550px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-bottom: 11px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }

        .g-1-2 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-bottom: 15px;
        }
      }

      .s-b {
        display: flex;
        justify-content: baseline;

        .s-b-1 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-top: 27px;
          margin-right: 36px;
        }

        .s-b-2 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-top: 27px;
          margin-right: 36px;
        }

        .s-b-3 {
          margin-top: 13px;
          margin-right: 60px;

          :nth-child(1) {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          :nth-child(2) {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
        }

        .s-b-4 {
          margin-top: 23px;

          :nth-child(1) {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          :nth-child(2) {
            img {
              display: inline-block;
              width: 15px;
              height: 15px;
            }

            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ce1200;
          }
        }
      }
    }

    .btn-p-btn {
      margin-right: 30px;

      .btn-p-btn-1 {
        width: 120px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #aaaaaa;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        margin-top: 20px;
        cursor: pointer;
        .hoverBorder();
      }

      .btn-p-btn-2 {
        width: 120px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #aaaaaa;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        margin-top: 10px;
        cursor: pointer;
        .hoverBorder();
      }
    }
  }
}
</style>
